/*
* Theme styles loader
* Used to load style variables into css classes
* to allow different component styles
* Component should not import theme variables
*/

/* Layout colors */
.app-styles {
  background: $app-background-color;
}

/* Header colors */
.header-styles {
  background-color: $header-background-color;
  color: $header-text-color;

  a,
  .as-link {
    color: $header-text-color !important;

    &:hover {
      color: $header-text-color-active !important;
    }

    &.active {
      color: $header-text-color-active !important;
    }
  }

  .topnav__user path {
    fill: $header-text-color !important;
  }

  .topnav {
    &__expiration {
      &__min {
        color: $expiration-min-color;
      }
      &__s {
        color: $expiration-s-color;
      }
    }
  }
}

/* Sidebar colors */
.sidebar-styles {
  background-color: $header-nav-background-color;
  color: $header-nav-text-color;
  a,
  .as-link {
    color: $sidebar-text-color !important;
    opacity: 0.6;
    transition: opacity 200ms ease;
    border-left-color: transparent;

    &:hover {
      opacity: 0.9;
    }

    &.active {
      opacity: 1;
      border-left-color: $header-text-color-active;
    }
  }
}

@media only screen and (min-width: 768px) {
  .sidebar {
    &__item {
      &--projects a {
        background-image: url($icon-projects);
      }
      &--scan a {
        background-image: url($icon-scan);
      }
      &--querysheet-management a {
        background-image: url($icon-querysheet-management);
      }
      &--datamodels a {
        background-image: url($icon-datamodels);
      }
      &--validations a {
        background-image: url($icon-validations);
      }
      &--outputfiles a {
        background-image: url($icon-outputfiles);
      }
      &--dashboard a {
        background-image: url($icon-dashboard);
      }
      &--time-tracking a {
        background-image: url($icon-time-tracking);
      }
      &--client-management a {
        background-image: url($icon-client-management);
      }
      &--admin a {
        background-image: url($icon-admin);
      }
      &--external a {
        background-image: url($icon-external);
      }
    }
  }
}

/* Footer colors */
.footer-styles {
  background: $footer-background-color;
  color: $footer-text-color;
}

.app {
  &__footer {
    &-nav-link {
      color: $footer-nav-link-color;
    }
  }
}

.hamburger {
  &-inner {
    background: $header-text-color !important;
    &:before,
    &:after {
      background: $header-text-color !important;
    }
  }
}

.header-logo-styles {
  background-image: url($header-logo-image);
}

.dashboard-capture-styles{
  &__portfolio{
    background-image: url($portfolio-overview-capture);
  }

  &__processing{
    background-image: url($processing-analytics-capture);
  }

  &__compliance{
    background-image: url($compliance-analytics-capture);
  }

  &__risks{
    background-image: url($risks-dollar-impact-capture);
  }

  &__average{
    background-image: url($average-cost-serve-capture);
  }

  &__exception{
    background-image: url($exception-trend-capture);
  }

  &__spend{
    background-image: url($spend-analysis-by-vendor-capture);
  }

  &__payment{
    background-image: url($payment-performance);
  }

  &__performanceesg{
    background-image: url($demo2_uk2);
  }

  &__co2emission{
    background-image: url($demo2_uk1);
  }
  &__provider{
    background-image: url($provider);
  }
}

.login-logo {
  background-image: url($loading-logo-image);
}

@media only screen and (min-width: 768px) {
  .header-styles {
    a,
    .as-link {
      &.active {
        border-bottom: 2px solid $header-text-color-active !important;
      }
    }
  }
}

/* Buttons */
.mat-button.mat-accent,
.mat-icon-button.mat-accent,
.mat-stroked-button.mat-accent {
  color: $button-text-color;
}

/* Breadcrumb colors */
.custom-breadcrumbs {
  color: $breadcrumb-text-color;
}

/* Projectlist header colors */
.projectlist-title {
  color: $projectlist-text-color;
}

.projectlist-button {
  background-color: $projectlist-button-background-color;
  color: $projectlist-button-text-color;

  &:hover {
    background-color: $projectlist-button-background-color-hover;
  }

  &.active {
    background-color: $projectlist-button-background-color-active;
  }
}

/* Spinner colors */
.spinner-ball-styles {
  color: $spinner-color-light;

  &.la-dark {
    color: $spinner-color-dark;
  }
}

/* KPIs colors */
.kpis-background-color {
  background-color: $kpis-background-color;
}

.kpis-text-color {
  color: $kpis-text-color;
}

.kpis-text-color-docsnum {
  color: $kpis-text-color-docsnum;
}

.kpis-text-color-quality {
  color: $kpis-text-color-quality;
}

.kpis-text-color-accuracy {
  color: $kpis-text-color-accuracy;
}

.kpis-text-color-datapoints {
  color: $kpis-text-color-datapoints;
}

.kpis-text-color-nodata {
  color: $kpis-text-color-nodata;
}

.kpis-text-color-status {
  color: $kpis-text-color-status;
}

.kpis-loading-text-color {
  color: $kpis-loading-text-color;
}

/* Status colors */
.color-nostatus {
  color: $nostatus-color;
}

.color-nostatus-dark {
  color: $nostatus-color-dark;
}

.color-success {
  color: $success-color;
}

.color-success-dark {
  color: $success-color-dark;
}

.color-partial-success {
  color: $partial-success-color;
}

.color-partial-success-dark {
  color: $partial-success-color-dark;
}

.color-info {
  color: $info-color;
}

.color-info-dark {
  color: $info-color-dark;
}

.color-warning {
  color: $warning-color;
}

.color-warning-dark {
  color: $warning-color-dark;
}

.color-danger {
  color: $danger-color;
}

.color-danger-dark {
  color: $danger-color-dark;
}

/* Project list cards */
.project-card-styles {
  background-color: $project-card-background-color;
  color: $project-card-text-color;
  border-color: $project-card-border-color;

  .projectcard__dates {
    color: $project-card-text-color;
  }
}

.project-card-title-color {
  color: $project-card-title-color;
}

.project-card-text-color-light {
  color: $project-card-text-color-light;
}

.project-card-text-color-lighter {
  color: $project-card-text-color-lighter;
}

.project-card-line-color {
  border-color: $project-card-line-color;
}

.project-card-analysis-text-color {
  color: $project-card-analysis-text-color;
}

.project-card-document-text-color {
  color: $project-card-document-text-color;
}

/* Querysheet card styles */
.querysheet_card_styles {
  background-color: $querysheet-card-background-color;
  color: $querysheet-card-text-color;
  border-color: $querysheet-card-border-color;
}

/* Login page */
.login-title-color {
  color: $login-title-color;
}

.login-text-color {
  color: $login-text-color;
}

.login-input-styles {
  border-color: $login-box-border-color;
  color: $login-box-text-color;

  &::-webkit-input-placeholder {
    color: $login-placeholder-color;
  }

  &::-moz-placeholder {
    color: $login-placeholder-color;
  }

  &:-ms-input-placeholder {
    color: $login-placeholder-color;
  }

  &:-moz-placeholder {
    color: $login-placeholder-color;
  }
}

.login-forgotpass-color {
  color: $login-forgotpass-color;

  &:hover {
    color: $login-forgotpass-color-hover;
  }
}

.login-background-image {
  background-image: url($login-background-image);
}

/* Query list page */
.query-text-color {
  color: $query-text-color;
}

.query-background-image {
  background-image: url($query-background-image);
}

/* Document view page */
.docview-nav-styles {
  color: $docview-nav-text-color;
  background-color: $docview-nav-background-color;
  border: 1px solid $docview-nav-border-color;

  &:hover {
    color: $docview-nav-text-color-hover;
  }
}

.export-button-styles {
  .exportbtn__drop-item {
    background: #2e2e38;
    color: #f6f6fa;
    &:hover {
      background: #747480;
    }
  }
}

// Project labels

.project {
  &__label {
    background-color: $project-label-background-color;
    color: $project-label-text-color;
  }
}

// Filter labels

.filters {
  &__label {
    background-color: $filter-label-background-color;

    span {
      color: $filter-label-text-color;
    }
  }

  &__title {
    color: $filter-label-title-text-color;
  }
}

.documents {
  &__div {
    background-color: $filter-label-background-color;

    &-selected {
      color: $filter-label-title-text-color;
    }
  }
}

.results {
  label {
    background-color: $filter-label-background-color;

    span {
      color: $filter-label-text-color;
    }
  }

  .span-message {
    color: $filter-label-title-text-color;
  }
}

// Time tracking

.timetracking {
  &__kpis-wrapper {
    background-color: $component-background-color;
  }

  &__item {
    background: rgb(47, 200, 212);
    background: linear-gradient(135deg, rgba(47, 200, 212, 1) 0%, rgba(255, 230, 0, 1) 100%);
  }

  &__item-back {
    background: $component-background-color;
  }

  &__tag {
    color: $tag-color;
  }

  &__tooltip {
    background: $tooltip-color;
  }

  .mat-icon {
    color: $icon-color;
  }
}

@media (min-width: 1190px) {
  .timetracking {
    &:before {
      content: "";
      background: $active-color;
    }

    &__item {
      &--subitems {
        .timetracking__name {
          color: $tag-color;
        }
        .timetracking__item-back {
          background: transparent !important;
        }
      }
    }
  }
}

.respontable.respontable--timetracking {
  .likelink {
    span {
      &:hover {
        color: $active-color;
      }
    }
  }
}

// Document details

.pagehead {
  &__title-clickable {
    &:hover {
      color: $active-color;
    }
  }
}

// Update children

.updatechildren {
  .doc_children {
    background: $updatechildren-background-color;
  }

  .doc_children_pages {
    background: $updatechildren-background-color;
  }

  .accordion {
    color: $accordion-color;
    background-color: $accordion-background-color;
  }
  .active,
  .accordion:hover {
    background-color: $accordion-background-color;
  }

  .accordion:after {
    color: $accordion-color;
  }

  .panel {
    background-color: $updatechildren-panel-color;
  }

  .span-ellipsis-process {
    color: $updatechildren-active-color;
  }

  .dataval {
    /* Rows and divisions */
    &__row {
      &--errors {
        color: $updatechildren-error-color;
      }
      &--alert {
        color: $updatechildren-error-color;
      }
    }

    /* General */
    &__box {
      background: $updatechildren-body-background-color;
      &--invalid {
        > .dataval__box-head {
          border-left: 38px solid $updatechildren-error-color;
        }
      }
    }

    /* Boxs */
    &__box {
      outline: 1px solid transparent;
      background: $updatechildren-body-background-color;
      &--expanded {
        > div > .dataval__box-title:before {
          border: 5px solid transparent;
          border-top: 7px solid $updatechildren-light-color;
        }
      }
      &--invalid {
        > .dataval__box-head {
          border-left: 38px solid $updatechildren-error-color;
        }
      }
      .dataval__box {
        background: $updatechildren-background-color;
        .dataval__box {
          background: $updatechildren-body-background-color;
          .dataval__box {
            background: $updatechildren-background-color;
            .dataval__box {
              background: $updatechildren-body-background-color;
            }
          }
        }
      }
    }
    &__box-head {
      background: $updatechildren-head-background-color;
    }
    &__box-title {
      &:before {
        border: 5px solid transparent;
        border-left: 7px solid $updatechildren-light-color;
      }
    }
    &__box-title-error {
      color: $updatechildren-error-color;
    }
    &__box-controls {
      button {
        background: transparent;
        color: $updatechildren-light-color;
        &:hover:before {
          color: $updatechildren-active-color;
        }
      }
    }
  }
}

// links

a:link {
  color: $active-color;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: $active-color;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: $active-color;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: $active-color;
  background-color: transparent;
  text-decoration: underline;
}

// sidebar links

.sidebar {
  a {
    font-weight: $sidebar-link-font-weight;
  }
}

// checkbox

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $table-checkbox-color;
}

// selection viewer

.selection-viewer {
  .mat-standard-chip {
    border-color: $selection-viewer-active-color;
  }
}

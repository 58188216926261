/**
* Chart general styles
* Please use this file only to style custom charts (D3 charts)
*/
.chart {
  &--updating {
    .chart__wrap {
      opacity: 0.2;
    }

    .chart__title {
      opacity: 0.2;
    }

    .chart__controls {
      opacity: 0.2;
    }

    pointer-events: none;
  }

  &__title {
    font-size: 12px;
    text-align: center;
    color: $chart-title-color;
  }

  &__axis {
    text {
      fill: $chart-axis-text-color;
      cursor: default;
      font-size: 10px;
      transition: opacity 350ms ease;
    }

    line {
      shape-rendering: geometricprecision;
      stroke: $chart-axis-line-color;
      pointer-events: none;
    }

    path {
      fill: transparent;
      stroke: transparent;
      pointer-events: none;
    }

    polyline {
      shape-rendering: geometricprecision;
      stroke: $chart-axis-line-color;
      fill: transparent;
      pointer-events: none;
    }

    circle {
      shape-rendering: geometricprecision;
      stroke: $chart-axis-line-color;
      fill: transparent;
      pointer-events: none;
    }
  }

  &__bar {
    transition: opacity 350ms ease;

    &:hover {
      opacity: 0.8;
    }
  }

  &__barvalue {
    fill: $chart-axis-text-color;
    font-size: 10px;
  }

  &__tooltip {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    font-family: sans-serif;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 2px;
    padding: 7px 10px;
    background: $chart-tooltip-background-color;
    color: $chart-tooltip-text-color;
    z-index: 9999;
    opacity: 0;
    transition: opacity 200ms ease;

    &.active {
      opacity: 1;
    }

    div::selection {
      background: transparent !important;
      color: inherit !important;
      text-shadow: inherit !important;
    }
  }

  &__brush {
    .overlay {
      fill: rgba(0, 0, 0, 0.1);
    }

    .selection {
      shape-rendering: auto;
    }
  }

  // &__controls {
  // }

  &__controls-item {
    display: inline-block;

    + .chart__controls-item {
      margin-left: 10px;
    }

    /* Material inputs */
    .mat-form-field-wrapper {
      margin-bottom: -1.25em;
    }

    .mat-form-field-infix {
      padding-top: 3px !important;
      margin-top: 6px;
    }
  }
}

/* Charts container */
.charts-dashboard {
  display: flex;
  flex-wrap: wrap;

  &__item {
    flex: 1 1 100%;
    margin-bottom: 20px;
  }
}

@media (min-width: 576px) {
  .charts-dashboard {
    flex-wrap: nowrap;

    &__item {
      flex: 1 1 100%;

      &--middle {
        flex: 1 1 50%;
      }

      &--quarter {
        flex: 1 1 25%;
      }

      &--threequarters {
        flex: 1 1 75%;
      }
    }
  }
}

.medic-dashboard {
  .chart {
    &__title {
      font-size: 12px;
    }

    &__axis {
      text {
        font-size: 12px;
      }
    }
  }
}

.timeline {
  &__container {
    position: relative;
    text-align: center;
    padding-bottom: 9px;
    width: 100%;
    margin-left: 0;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      height: 3px;
      width: 0;
      left: -100%;
      background: transparent;
      z-index: 2;
    }
    &--success:before,
    &--manual-success:before {
      background: $timeline-success-color;
      width: 200%;
      transition: width 500ms ease-out;
    }
    &--failure:before,
    &--manual-failure:before {
      background: $timeline-failure-color;
      width: 200%;
      transition: width 500ms ease-out;
    }
    &--pending:before {
      background: $timeline-pending-color;
      width: 200%;
      transition: width 500ms ease-out;
    }
    &--warning:before {
      background: $timeline-warning-color;
      width: 200%;
      transition: width 500ms ease-out;
    }
    &--in-progress:before {
      background: $timeline-in-progress-color;
      width: 200%;
      transition: width 500ms ease-out;
    }
  }

  &__list {
    display: flex;
    justify-content: space-around;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      background: $timeline-bar-color;
      width: 76%;
      height: 2px;
      z-index: 1;
      top: 39px;
      margin: 0;
    }
  }

  &__sublist {
    margin: 0;
    display: none;
  }

  &__item {
    text-align: center;
    position: relative;
    z-index: 1;
    &.is-active {
      > .timeline__point:before {
        display: block;
      }
      .timeline__sublist {
        display: inline-block;
      }
    }
    &.is-disabled {
      cursor: default;
      pointer-events: none;
      .timeline__label {
        color: $timeline-disabled-color;
      }
      .timeline__point {
        &:after {
          opacity: 0.6;
        }
      }
    }
  }

  &__label {
    cursor: pointer;
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
    margin-top: 0;
    transition: opacity 250ms ease;
    &:hover {
      opacity: 0.6;
    }
  }

  &__point {
    cursor: pointer;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0 auto;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background-color: $timeline-bar-color;
    transition: background-color 250ms ease, transform 250ms ease;

    &:before {
      content: "";
      width: 4px;
      height: 8px;
      position: absolute;
      top: 34px;
      left: 16px;
      display: none;
      transition: background-color 250ms ease, top 250ms ease;
    }

    &:after {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 14px;
      font-size: inherit;
      text-rendering: auto;
      line-height: 36px;
      color: white;
      pointer-events: none;
    }

    &:active {
      transform: scale(1.05);
      &:before {
        top: 32px;
      }
    }

    &--subpoint {
      width: 16px;
      height: 16px;
      margin: 12px 2px;
      &:after {
        font-size: 10px;
        line-height: 16px;
        vertical-align: top;
      }
    }

    &--success,
    &--partial-success,
    &--manual-success {
      background-color: $timeline-success-color;
      &:before {
        background-color: $timeline-success-color;
      }

      &:hover {
        background-color: $timeline-success-hover-color;
        &:before {
          background-color: $timeline-success-hover-color;
        }
      }

      &:after {
        content: "\f00c";
      }
    }

    &--failure,
    &--manual-failure {
      background-color: $timeline-failure-color;
      &:before {
        background-color: $timeline-failure-color;
      }

      &:hover {
        background-color: $timeline-failure-hover-color;
        &:before {
          background-color: $timeline-failure-hover-color;
        }
      }

      &:after {
        content: "\f00d";
      }
    }

    &--pending {
      background-color: $timeline-pending-color;
      &:before {
        background-color: $timeline-pending-color;
      }
      &:hover {
        background-color: $timeline-pending-hover-color;
        &:before {
          background-color: $timeline-pending-hover-color;
        }
      }
      &:after {
        content: "\f068";
      }
    }

    &--warning {
      background-color: $timeline-warning-color;
      &:before {
        background-color: $timeline-warning-color;
      }
      &:hover {
        background-color: $timeline-warning-hover-color;
        &:before {
          background-color: $timeline-warning-hover-color;
        }
      }
      &:after {
        content: "\f12a";
      }
    }

    &--in-progress {
      background-color: $timeline-in-progress-color;
      &:before {
        background-color: $timeline-in-progress-color;
      }
      &:hover {
        background-color: $timeline-in-progress-hover-color;
        &:before {
          background-color: $timeline-in-progress-hover-color;
        }
      }
      &:after {
        content: "\f017";
      }
    }

    &--skipped {
      background-color: $timeline-skipped-color;
      &:before {
        background-color: $timeline-skipped-color;
      }
      &:hover {
        background-color: $timeline-skipped-hover-color;
        &:before {
          background-color: $timeline-skipped-hover-color;
        }
      }
      &:after {
        content: "\f111";
      }
    }
  }
}

@media (min-width: 768px) {
  .timeline {
    &__container {
      margin-left: -15px;
      width: calc(100% + 30px);
    }
  }
}

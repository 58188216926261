/**
* Search input (search-box) styles
*/

.search-box {
  color: $searchbox-text-color;
  font-size: 14px;
  position: relative;
  input {
    background-color: $searchbox-background-color;
    border: 1px solid $searchbox-border-color;
    border-radius: 20px;
    color: inherit;
    padding: 5px 30px 5px 12px;
    width: 100%;
    max-width: -webkit-fill-available;
    &:focus {
      border-color: $searchbox-border-color-focus;
      outline: 0;
    }
    &::placeholder {
      color: $searchbox-placeholder-text-color;
      font-weight: lighter;
    }
    + button {
      background: transparent;
      border: 0;
      color: inherit;
      font: normal normal normal 14px/1 FontAwesome;
      outline: 0 !important;
      opacity: 0.5;
      position: absolute;
      right: 6px;
      top: 5.5px;
      transition: opacity 200ms ease;
      &:hover {
        opacity: 1;
      }
      &:before {
        content: '\f002';
        display: block;
        height: 100%;
        width: 100%;
      }
    }
  }
  .separating-bar {
    border-left: 1px solid #8f8f96;
    height: 65%;
    position: relative;
    right: 35px;
  }
  .valcollaplist__cancel {
    background: transparent;
    border: 0;
    color: inherit;
    font: normal normal normal 14px/1 FontAwesome;
    outline: 0 !important;
    opacity: 0.5;
    position: absolute;
    right: 40px;
    top: -2px;
    transition: opacity 200ms ease;
    &:hover {
      color: #f5f5f9;
      opacity: 1;
    }
    &:before {
      content: '\f00d';
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

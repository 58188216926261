tree-viewport {
  max-height: calc(51vh);

  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }

    &::-webkit-scrollbar-track {
      visibility: visible;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }
  }

  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  &::-webkit-scrollbar-track {
    visibility: hidden;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  .empty-tree-drop-slot {
    visibility: hidden;
  }
}

.tree .tree-node {
  background-color: #2E2E37;
  margin-bottom: 4px;
}

.tree .node-wrapper {
  width: 100%;
  align-items: center;
  box-sizing: border-box;

  &:hover {
    border: 2px solid rgba(0, 0, 0, 0.3);
  }
}

.tree .node-content-wrapper {
  flex-grow: 1;

  tree-node-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    min-height: 36px;

    span {
      flex: 1;
      vertical-align: middle;
    }
  }
}

.tree .node-content-wrapper-active,
.tree .node-content-wrapper-focused,
.tree .node-content-wrapper:hover {
  box-shadow: none;
  background: none;
}

.tree .node-content-wrapper.is-dragging-over {
  background: #dddddd;
  box-shadow: inset 0 0 1px #999;
}

.tree .node-drop-slot.is-dragging-over {
  background: aliceblue;
  border:  solid 2px;
}

.tree .empty-tree-drop-slot .node-drop-slot {
  background: blue;
}

.tree .toggle-children {
  display: none;
}

.tree .toggle-children-placeholder {
  display: none;
}

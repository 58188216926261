/**
* Main styles file
*
* Please use this file only if there isn't a specific stylesheet
* to your additions
*/

/* Body */
body {
  background-color: $body-background-color;
  color: $body-text-color;
}

/* Tab container */
.tab-content {
  padding: 10px 10px 50px;
  &--nopadding {
    padding: 0;
  }
}

/*
* Common element styling
*/

button {
  outline: 0 !important;
}

/* Placeholders */
::-webkit-input-placeholder {
  color: $input-placeholder-text-color;
}

::-moz-placeholder {
  color: $input-placeholder-text-color;
}

:-ms-input-placeholder {
  color: $input-placeholder-text-color;
}

:-moz-placeholder {
  color: $input-placeholder-text-color;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $scrollbar-track-color;
  $box-shadow: inset 0 0 6px $scrollbar-track-shadow-color;
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $scrollbar-thumb-color;
  $box-shadow: inset 0 0 6px $scrollbar-thumb-shadow-color;
  -webkit-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

/* Texts */
p {
  margin: 0px;
}

mark {
  background-color: $mark-background-color;
}

/* Tables */
table {
  font-size: 12px;
}

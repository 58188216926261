/**
* Pagination component styles
*/

.custom-pagination {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  min-height: 25px;
  background: $pagination-background-color;
  color: $pagination-text-color;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: right;
  font-size: 12px;

  .ngx-pagination {
    margin-bottom: 0 !important;
    position: relative;

    a {
      color: $pagination-link-color;
      border-radius: 12px;
      &:hover {
        text-decoration: none;
        color: $pagination-link-color-hover;
      }
    }
    .current {
      border-radius: 12px;
      background: $pagination-current-background-color !important;
      color: $pagination-current-text-color;
    }
  }
  .show-for-sr {
    display: none;
  }

  .la-ball-circus {
    color: $spinner-color-light;
    right: 70px;
    top: 8px;
    position: absolute !important;
  }
}

/*@media only screen and (max-width: 1500px) {
  .custom-pagination {
    font-size: 8px;
  }
}*/

// Theming of components related to documents.

.docbuttons {
  &__button {
    color: $documents-color;
    background: $documents-button-background-color;
    border: 1px solid $documents-color;
  }
}

.docinfo,
.reviewer {
  &__header {
    background: $documents-background-color;
  }

  &__buttons {
    button {
      background: $docinfo-background-color;
      border: 1px solid $docinfo-color;
      color: $docinfo-color;
    }
  }

  &__body {
    &__current {
      color: $documents-active-color;
    }
  }

  .linear {
    background-color: $docinfo-color;
  }

  .panel {
    background-color: $docinfo-panel-background-color;
  }

  .doc_children {
    background: $docchildren-background-color;
  }

  table {
    background: $docinfo-panel-background-color;
  }
}

.valoverride {
  &__newcomment {
    textarea {
      background: $valoverride-newcomment-background-color;
      border: 1px solid $valoverride-newcomment-color;
      border-bottom: 1px solid $valoverride-newcomment-color !important;
      color: $valoverride-newcomment-color;
    }
  }

  &__comment {
    background-color: $valoverride-comment-background-color;
    border-bottom: 1px solid $valoverride-comment-border-color;
    border-right: 1px solid $valoverride-comment-border-color;

    &-file {
      &:hover {
        color: $valoverride-file-hover-color;
      }
    }
  }
}

.logtrack {
  &__header {
    background: $docs-header-background-color;
  }

  &__wrap {
    background: $docs-body-background-color;
  }

  /* document log icon */
  &__icon {
    &:before {
      background: $logtrack-line-color;
    }

    span {
      &:before {
        background: $docs-body-background-color;
      }
    }
  }
}

.documentrender {
  &__controls {
    background: $docs-navpage-color;
  }

  &__search {
    input {
      background-color: $docs-controls-background-color;
      border: 1px solid $docs-controls-border-color;
    }
  }

  &__nav-page {
    input {
      background: $docs-navpage-input-background-color;
      border: 1px solid $docs-controls-border-color;
      color: $docs-controls-color;
    }
  }

  &__chunknav-text {
    input {
      background-color: $docs-body-background-color;
      border: 1px solid $docs-controls-border-color;
    }
  }
}

.documentthumbnails {
  &__item {
    &:hover {
      box-shadow: 0 0 0 2px $thumbnail-background-color, 0 0 0px 4px $thumbnail-hover-color;
    }

    &.is-selected {
      box-shadow: 0 0 0 2px $thumbnail-background-color, 0 0 0px 4px $thumbnail-active-color;
    }
  }

  &__stub {
    background-color: $thumbnail-stub-background-color;
  }
}

/**
* Reviewers pages and components
*/

.reviewers {
  &__head {
    padding: 10px 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__head-wrap {
    display: flex;

    .reviewers__title {
      flex: 1 1 auto;
    }
  }

  &__title {
    font-size: 16px;
    line-height: 1;
  }

  &__button {
    background: $filter-button-background-color;
    border: 1px solid $filter-button-color;
    color: $filter-button-color;
    transition: opacity 250ms ease;
    margin-bottom: 10px;

    &:hover {
      opacity: 0.6;
    }

    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      color: inherit;
    }

    &:disabled,
    &.disabled {
      cursor: no-drop;
    }

    &--filter {
      align-self: baseline;
      height: 25px;

      &:before {
        content: '\f0b0';
      }
    }

    &--edit {
      align-self: baseline;
      height: 20px;
      font-size: 15px;
      margin-left: 10px;
      border: none;

      &:before {
        content: '\f044';
      }
    }
  }

  &__filters {
    flex: 1 1 100%;
  }


}
